import React from 'react';
import './VaduAplu.css'; // Ensure your CSS is updated accordingly

function VaduAplu() {
  return (
    <div className="vadu-aplu-container">
      {/* Header Section */}
      <header className="header">
        <h1>VaduAplu IT Services</h1>
        <p className="tagline">Bringing Your Digital Vision to Life</p>
      </header>

      {/* Services Section */}
      <section className="services">
        <h2>🚀 Our Services</h2>
        <ul>
          <li>🎬 Videography & Editing</li>
          <li>📸 Professional Photography</li>
          <li>🖥️ Website Development & Design</li>
          <li>🎨 Graphic Design & Branding</li>
          <li>📢 Flyers & Digital Marketing</li>
          <li>🔍 SEO & Online Presence Optimization</li>
          <li>💻 Custom Software Development</li>
          <li>🛠️ IT Consulting & Support</li>
        </ul>
      </section>

      {/* Get in Touch Section */}
      <section className="contact">
        <h2>📞 Get in Touch</h2>
        <p>Email: <a href="mailto:contact@vaduaplu.com">contact@vaduaplu.com</a></p>
        <p>Phone: <a href="tel:2102517854">(210) 251-7854</a></p>
        <a href="mailto:contact@vaduaplu.com" className="cta-button">Request a Consultation</a>
      </section>
    </div>
  );
}

export default VaduAplu;
