import React from 'react';
import { Link } from 'react-router-dom';
import './FleaMarketPlace.css'; // Import the updated CSS file

const categories = [{ name: 'Coming Soon' }];

function FleaMarket() {
  return (
    <div className="fleamarket-page">
      <div className="categories-container">
        {categories.map((category, index) => (
          <div key={index} className="category-tile">
            <span className="category-name">{category.name}</span>
          </div>
        ))}
      </div>

      <div className="slogan-container">
        <h3 className="slogan">
          🛍️ "One Market, Endless Cultures—Treasures from Every Corner of the World!" 🌎🎭
        </h3>
      </div>

      <div className="back-home-tile">
        <Link to="/" className="back-home-link">
          Go Back to Homepage
        </Link>
      </div>
    </div>
  );
}

export default FleaMarket;
