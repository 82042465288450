import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Import the CSS file
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../../NewTile.css";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="footerSection">
      {/* Back to Top Section */}
      <div className="back-to-top-strip" onClick={scrollToTop}>
        Back to top
      </div>

      {/* Footer Content */}
      <div className="footer-content">
        {/* Navigation and Search Section */}
        <div className="nav-section">
          <div className="search-container">
            <i className="fas fa-search search-icon"></i>
            <input
              type="text"
              placeholder="Search GoOurHub"
              className="search-bar"
            />
          </div>
          <div className="nav-buttons">
            <button
              className="nav-button"
              onClick={() => (window.location.href = "/")}
            >
              Home
            </button>
            <button
              className="nav-button"
              onClick={() => (window.location.href = "/about-us")}
            >
              About
            </button>
            <button
              className="nav-button"
              onClick={() => (window.location.href = "/contact")}
            >
              Contact
            </button>
          </div>
        </div>

        {/* Social Media Section */}
        <div className="social-media-section">
          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61560982405400" className="social-icon" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://www.instagram.com/goourhub/" className="social-icon" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#" className="social-icon" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-tiktok"></i>
            </a>
          </div>
        </div>

        {/* Footer Text */}
        <div className="footerText">
          © GoOurHub 2025. All rights reserved.
        </div>
      </div>
    </div>
  );
}

export default Footer;
