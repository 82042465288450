import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './African.css';
import ProductCategories from './ProductCategories';

function African() {
  const africanVillageImage = require('../images/AV7.jpg');
  const selamFoodMartImage = require('../images/selam3.jpeg');

  const imageTiles = [
    { name: 'African Village', icon: '🏘️', url: '/africanvillage', image: africanVillageImage },
  ];

  const imageTiles2 = [
    { name: 'Selam Food Mart', icon: '🍎', url: '/selamfoodmart', image: selamFoodMartImage },
  ];

  const filteredCategories = [
    { name: 'Boutique', icon: '👜' },
    { name: 'Salon', icon: '💇‍♀️' },
    { name: 'Restaurants', icon: '🍴', url: '/AfricanVillage' },
    { name: 'Health', icon: '⚕️' },
    { name: 'Student Services', icon: '🎓' },
    { name: 'Beauty', icon: '💄' },
    { name: 'Tailor', icon: '👗' },
    { name: 'Venues', icon: '🏢' },
    { name: 'Photography', icon: '📷' },
    { name: 'Event Management', icon: '🎉' },
    { name: 'Jewelry', icon: '💍' },
    { name: 'Food Truck', icon: '🚚' },
  ];

  return (
    <div>
      {/* 1st Section: Filtered Product Categories */}
      <div className="product-categories-section">
        <ProductCategories categories={filteredCategories} />
      </div>

      <div className="your-store-container">
        {/* First Block: Grid of Clickable Image Tiles */}
        <div className="image-grid">
          {imageTiles.map((tile, index) => (
            <Link to={tile.url} key={index} className="tile-link">
              <div className="tile">
                <img src={tile.image} alt={tile.name} className="tile-image" />
                <span className="tile-name">{tile.name}</span>
              </div>
            </Link>
          ))}
        </div>

        {/* Second Block: Grid of Clickable Image Tiles */}
        <div className="image-grid">
          {imageTiles2.map((tile, index) => (
            <Link to={tile.url} key={index} className="tile-link">
              <div className="tile">
                <img src={tile.image} alt={tile.name} className="tile-image" />
                <span className="tile-name">{tile.name}</span>
              </div>
            </Link>
          ))}
        </div>

        {/* Third Block: Special Offers */}
        <div className="special-offers-tile">
          <h3>Today's Best Offer – Shop and Save Big!</h3>
          <p><i>Get 10% off when you mention "GoOurHub" to African Village</i></p>
          <p><i>Get best price when you join GoOurHub</i></p>
          <button className="offer-button">View Offers</button>
        </div>
      </div>
    </div>
  );
}

export default African;
