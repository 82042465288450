import React from 'react';

function ServiceCategories() {
  const services = [
    { name: 'Boutique', icon: '👜' },
       
       { name: 'Home Help', icon: '🏡' },
       { name: 'Realtors', icon: '🏘️' },
       { name: 'Transportation', icon: '🚗' },
       { name: 'Legal', icon: '⚖️' },
       { name: 'Education', icon: '📚' },
       { name: 'Health', icon: '⚕️' },
       { name: 'Student Services', icon: '🎓' },
       { name: 'Beauty', icon: '💄' },
       { name: 'Tailor', icon: '👗' },
       { name: 'Venues', icon: '🏢' },
       { name: 'Photography', icon: '📷' },
       { name: 'Event Management', icon: '🎉' },
       { name: 'Jewelry', icon: '💍' },
     
    { name: 'Gardening', icon: '🌱' },
    { name: 'Catering', icon: '🍲' },
    { name: 'Pet Care', icon: '🐾' },
    { name: 'Child Care', icon: '👶' },
    { name: 'Elderly Care', icon: '🧓' },
    
    
    { name: 'Marketing', icon: '📈' },
    { name: 'Design', icon: '🎨' },
    { name: 'Legal Advice', icon: '⚖️' },
    { name: 'Fitness Training', icon: '🏋️' },
    { name: 'Repair Services', icon: '🔧' },
    { name: 'Transportation', icon: '🚛' },
    { name: 'Tutoring', icon: '📖' },
    { name: 'Consulting', icon: '🤝' },
    { name: 'Personal Shopping', icon: '🛍️' },
    { name: 'Accommodation', icon: '🏠' },
    { name: 'Construction', icon: '🔧' },
    { name: 'Storage', icon: '📦' },
    { name: 'Translation', icon: '🌐' },
    { name: 'Worship Places', icon: '⛪' },
    { name: 'Sports', icon: '🏅' },
    { name: 'Entertainment', icon: '🎭' },
    { name: 'Employment', icon: '💼' },
    { name: 'Financial', icon: '💰' },
    
    { name: 'Plumbing', icon: '🚰' },
    { name: 'Electrical', icon: '💡' },
  

  ];

  return (
    <div className="service-categories-container">
      {/* Coming Soon Bar */}
      <div className="coming-soon">
        <hr className="thin-line" />
        <span className="coming-soon-text">Coming Soon</span>
        <hr className="thin-line" />
      </div>

      {/* Service Tiles */}
      <div className="new-tile-container">
        {services.map((service, index) => (
          <div key={index} className="new-tile">
            <span className='icon-for-new-tile'>{service.icon}</span>
            <span>{service.name}</span>     
          </div>
        ))}
      </div>
    </div>
  );
}

export default ServiceCategories;
