import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function ProductCategories({ searchQuery = "" }) { // ✅ Ensure searchQuery has a default value
  const defaultCategories = [
    { name: 'Grocery Stores', icon: '🛒', url: '/visitgroceries' },
    { name: 'Restaurants', icon: '🍴', url: '/visitresturants' },
    { name: 'Food Truck', icon: '🚚', url: '/visitfoodtrucks' },
    { name: 'Kosher', icon: '🕍', url: '/visitkosherplaces' },
    { name: 'Vegan', icon: '🥗', url: '/visitveganplaces' },
    { name: "Farmers’s Market", icon: '🌽', url: '/farmersmarket' },
    { name: "Flea Market Place", icon: '🛍️', url: '/fleamarketplace' },
    { name: 'Cleaning services', icon: '🧹', url: '/cleaning-services' },
    { name: 'IT Services', icon: '💻', url: '/itservices' },
  ];

  const [categoriesToRender, setCategoriesToRender] = useState(defaultCategories);

  // ✅ Ensure `searchQuery` is always a string before filtering
  useEffect(() => {
    const query = searchQuery ? searchQuery.toLowerCase().trim() : "";

    if (query === "") {
      setCategoriesToRender(defaultCategories); // Show all tiles if search is empty
    } else {
      const filteredCategories = defaultCategories.filter(category =>
        category.name.toLowerCase().includes(query)
      );
      setCategoriesToRender(filteredCategories.length > 0 ? filteredCategories : []);
    }
  }, [searchQuery]);

  return (
    <div className="new-tile-container-new">
      {categoriesToRender.length > 0 ? (
        categoriesToRender.map((category, index) => (
          <div key={index} className="new-tile">
            {category.url ? (
              <Link to={category.url} style={{ textDecoration: 'none', color: 'inherit' }}>
                <span className="icon-for-new-tile">{category.icon}</span>
                <span>{category.name}</span>
              </Link>
            ) : (
              <>
                <span className="icon-for-new-tile">{category.icon}</span>
                <span>{category.name}</span>
              </>
            )}
          </div>
        ))
      ) : (
        <p>No results found.</p>
      )}
    </div>
  );
}

export default ProductCategories;
