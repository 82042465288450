import React from 'react';
import { Link } from 'react-router-dom';
import './FarmersMarket.css'; // Import the updated CSS file

const categories = [{ name: 'Coming Soon' }];

function VisitResturants() {
  return (
    <div className="farmersmarket-page">
      <div className="categories-container">
        {categories.map((category, index) => (
          <div key={index} className="category-tile">
            <span className="category-name">{category.name}</span>
          </div>
        ))}
      </div>

      <div className="slogan-container">
        <h2 className="slogan">
          🌍 Uniting Markets, Connecting Cultures 🌾 <br /> 
          A Global Harvest in Every Basket! 🍎
        </h2>
      </div>

      <div className="back-home-tile">
        <Link to="/" className="back-home-link">
          Go Back to Homepage
        </Link>
      </div>
    </div>
  );
}

export default VisitResturants;
