import React from 'react';
import './Mexican.css'; // Add this CSS for styling
import ProductCategories from './ProductCategories'; // Import ProductCategories

// Dynamically import all images from the folder
const images = require.context('../images', false, /\.(png|jpe?g|svg)$/);

function Mexican() {
  // Map all images into an object for easy access
  const imageMap = images.keys().reduce((acc, path) => {
    const imageName = path.replace('./', '').replace(/\.\w+$/, ''); // Remove './' and file extension
    acc[imageName] = images(path);
    return acc;
  }, {});

  const imageTiles = [
   
  ];

  const imageTiles2 = [
    
  ];

  const filteredCategories = [
  
    { name: 'Boutique', icon: '👜' },
    { name: 'Restaurants', icon: '🍴' },
    { name: 'Health', icon: '⚕️' },
  
    { name: 'Student Services', icon: '🎓' },
    { name: 'Beauty', icon: '💄' },
    { name: 'Tailor', icon: '👗' },
    { name: 'Venues', icon: '🏢' },
    { name: 'Photography', icon: '📷' },
    { name: 'Event Management', icon: '🎉' },
    { name: 'Jewelry', icon: '💍' },
    { name: 'Food Truck', icon: '🚚' },
  ];

  return (
    <div>
      {/* 1st Section: Filtered Product Categories */}
      <div className="product-categories-section">
        <ProductCategories categories={filteredCategories} />
      </div>

      <div className="your-store-container">
        {/* First Block: Grid of Image Tiles */}
        <div className="image-grid">
          {imageTiles.map((tile, index) => (
            <div key={index} className="tile">
              <img src={tile.image} alt={tile.name} className="tile-image" />
              <span className="tile-name">{tile.name}</span>
            </div>
          ))}
        </div>

        {/* Second Block: Tile Containing a List */}
        <div className="image-grid">
          {imageTiles2.map((tile, index) => (
            <div key={index} className="tile">
              <span className="tile-name">{tile.name}</span>
            </div>
          ))}
        </div>

        {/* Third Block: Special Offers */}
        <div className="special-offers-tile">
          <h3>Today's Best Offer – Shop and Save Big!</h3>
          <p><i>get best price when you join GoOurHub</i></p>
          <button className="offer-button">View Offers</button>
        </div>
      </div>
    </div>
  );
}

export default Mexican;
