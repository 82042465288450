import React from 'react';
import { Link } from 'react-router-dom';
import './VisitResturants.css'; // Import the CSS file for styling

// Define your three categories with names, icons, and URLs
const categories = [
 
  { name: 'Pita Plus', icon: '🥙', url: '/pitaplus' },
  
];

function VisitResturants() {
  return (
    <div className="visit-resturants-page">
      <div className="categories-container">
        {categories.map((category, index) => (
          <div key={index} className="category-tile">
            <Link to={category.url} className="category-link">
              <span className="category-icon">{category.icon}</span>
              <span className="category-name">{category.name}</span>
            </Link>
          </div>
        ))}
      </div>

      {/* Slim long tile that navigates back to homepage */}
      <div className="back-home-tile">
        <Link to="/" className="back-home-link">
          Go Back to Homepage
        </Link>
      </div>
    </div>
  );
}

export default VisitResturants;
