import React, { useState, useEffect, useRef } from "react";
import logo from "../../logo.png";
import "../../NewTile.css";
import "./header.css";

import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import { FaMicrophone, FaBars } from "react-icons/fa"; // Import mic and hamburger icon

function Header({ onSearch }) {
  const [isListening, setIsListening] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchText, setSearchText] = useState(""); // State for search input
  const menuRef = useRef(null); // Ref for hamburger menu

  // Handle manual search input
  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchText(query);
    onSearch(query); // Ensure search runs when typing
  };

  // Handle Voice Search (Dynamic Typing + Auto Search on End)
  const handleMicClick = () => {
    if (!("webkitSpeechRecognition" in window)) {
      alert("Speech recognition not supported. Please use Chrome.");
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = "en-US";
    recognition.continuous = false;
    recognition.interimResults = true; // Capture words as they are spoken

    recognition.onstart = () => setIsListening(true);

    recognition.onresult = (event) => {
      let transcript = "";
      for (let i = event.resultIndex; i < event.results.length; i++) {
        transcript += event.results[i][0].transcript;
      }
      setSearchText(transcript); // Update input field live
    };

    recognition.onend = () => {
      setIsListening(false);
      onSearch(searchText.trim()); // ✅ Ensure search runs after speech input ends
    };

    recognition.start();
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <div className="header-container">
      <div className="header-pair-container">
        {/* Logo Section */}
        <div className="logo-section">
          <Link to="/">
            <img src={logo} alt="GoOurHub Logo" className="logo-image" />
          </Link>
          <span className="logo-text">
            GoOurHub{" "}
            <i className="connecting-text">- Connecting you to the world in a snap!</i>
          </span>
        </div>

        {/* Social Media Section */}
        <div className="social-media-section">
          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61560982405400" className="social-icon" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://www.instagram.com/goourhub/" className="social-icon" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#" className="social-icon" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-tiktok"></i>
            </a>
          </div>
        </div>
      </div>

      {/* Navigation and Search Section */}
      <div className="nav-section">
        <div className="search-container" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <button className="mic-button" onClick={handleMicClick}>
            <FaMicrophone size={12} color={isListening ? "red" : "black"} />
          </button>
          <i className="fas fa-search search-icon"></i>
          <input 
            type="text" 
            placeholder="Search GoOurHub" 
            className="search-bar" 
            value={searchText} // Sync with state
            onChange={handleSearchChange} 
          />
        </div>

        {/* Hamburger Menu for Mobile View */}
        <div className="hamburger-container" ref={menuRef}>
          <button className="hamburger-button" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <FaBars size={24} color="#e7deee" />
          </button>
          {isMenuOpen && (
            <div className="menu-dropdown">
              <Link to="/" className="menu-item">Home</Link>
              <Link to="/about-us" className="menu-item">About</Link>
              <Link to="/contact" className="menu-item">Contact</Link>
              <Link to="/forum" className="menu-item">Community Forum</Link>
              <Link to="/review-system" className="menu-item">Review and Ratings</Link>
              <Link to="/dashboard" className="menu-item">My Dashboard</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
