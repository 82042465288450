import React, { useState } from 'react'; 
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import './Menu.css';
import Header from './goourhub/component/Header';
import Footer from './goourhub/component/Footer';
import YourStore from './goourhub/component/YourStore';
import './NewTile.css';
import './goourhub/main/aligners.css';
import 'flag-icons/css/flag-icons.min.css'; // Import flag icons
import ProductCategories from './goourhub/component/ProductCategories';
import PitaPlus from './goourhub/component/PitaPlus';
import SelamFoodMart from './goourhub/component/SelamFoodMart';
import ServiceCategories from './goourhub/component/ServiceCategories';
import ImportantLinks from './goourhub/component/ImportantLinks';

import CommunityConnect from './goourhub/component/CommunityConnect';
import Template from './goourhub/main/template';
import TransitioningSectionForAd from './goourhub/component/TransitioningSectionForAd';
import VendorTemplate1 from './goourhub/component/VendorTemplate1'; 
import AfricanVillage from './goourhub/component/AfricanVillage';
import VendorTemplate2 from './goourhub/component/VendorTemplate2'; 
import VendorTemplate3 from './goourhub/component/VendorTemplate3'; 
import ContactUs from './goourhub/component/ContactUs';
import SignInModal from './goourhub/component/SignInModal';
import SignUpModal from './goourhub/component/SignUpModal';
import Mexican from './goourhub/component/Mexican';
import American from './goourhub/component/American';
import African from './goourhub/component/African';
import AboutUs from './goourhub/component/AboutUs';
import CommunityForum from './goourhub/component/CommunityForum';
import SliderForMobile from './goourhub/component/SliderForMobile';
import LanguageDropdown from './goourhub/component/LanguageDropdown';
import ReviewSystem from './goourhub/component/ReviewSystem';
import UserDashboard from './goourhub/component/UserDashboard';
import VisitResturants from './goourhub/component/VisitResturants';
import VisitGroceries from './goourhub/component/VisitGroceries';
import Eritrean from './goourhub/component/Eritrean';
import Ethiopian from './goourhub/component/Ethiopian';
import { getData, filter } from './goourhub/data/data';
import VisitFoodTrucks from './goourhub/component/VisitFoodTrucks';
import VisitKosherPlaces from './goourhub/component/VisitKosherPlaces';
import FarmersMarket from './goourhub/component/FarmersMarket';
import FleaMarketPlace from './goourhub/component/FleaMarketPlace';
import VisitVeganPlaces from './goourhub/component/VisitVeganPlaces';
import CleaningServices from './goourhub/component/CleaningServices';
import PetersEliteCleaning from './goourhub/component/PetersEliteCleaning';
import ITServices from './goourhub/component/ITServices';
import VaduAplu from './goourhub/component/VaduAplu';
import PartnerSignup from './goourhub/component/PartnerSignUp';
import ThankYouPartner from './goourhub/component/ThankYouPartner';

function GoHubApp() {
  const [language, setLanguage] = useState("english"); // Default language
  const { frequentlyVisitedCommunities, communities, categories } = getData(language);
  const [searchQuery, setSearchQuery] = useState(""); // ✅ State for search
  const [filteredResults, setFilteredResults] = useState(categories); // ✅ State for filtered categories

  // ✅ Handle search and filter categories
  const handleSearch = (query) => {
    setSearchQuery(query); // Update the search query

    if (!query.trim()) {
      setFilteredResults(categories); // Show all if search is empty
    } else {
      const filtered = categories.filter((category) =>
        category.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredResults(filtered.length > 0 ? filtered : []);
    }
  };

  return (
    <Router>
      <div className="App">
        <Template>
          {/* Header Section */}
          <div className="headerSection">
            <Header onSearch={handleSearch} /> {/* ✅ Pass search handler */}
          </div>

         {/* Left Menu Section */}
         <div className="menuSection">
            <div className="menu-section">
              <LanguageDropdown selectedLanguage={language} onLanguageChange={setLanguage} />
              <span style={{ fontSize: '1.0rem', fontWeight: 'bold' }}>Community Hub</span>
              <CommunityConnect countries={communities} frequentlyVisited={frequentlyVisitedCommunities} />
              <SliderForMobile countries={[...frequentlyVisitedCommunities, ...communities]} />
            </div>
          </div>

 {/* Main Section */}
<div className="mainSection">
    <Routes>
        <Route
            path="/"
            element={
                <>
                    {/* ✅ Pass both key={language} and searchQuery */}
                    <ProductCategories 
                        key={language} 
                        categories={filteredResults} 
                        defaultCategories={getData(language).categories} 
                        searchQuery={searchQuery} 
                    />
                    
                    <div className="new-tile-container-seperator"></div>
                    <ServiceCategories />
                    <div className="new-tile-container-seperator"></div>
                </>
            }
              />
           <Route path="/your-store" element={<YourStore />} />
              <Route path="/vaduaplu" element={<VaduAplu />} />
              <Route path="/visitresturants" element={<VisitResturants />} />
              <Route path="/itservices" element={<ITServices />} />      
              <Route path="/visitgroceries" element={<VisitGroceries />} />
              <Route path="/visitveganplaces" element={<VisitVeganPlaces />} />
              <Route path="/peterselitecleaning" element={<PetersEliteCleaning />} />
              <Route path="/cleaning-services" element={<CleaningServices />} />
              <Route path="/visitkosherplaces" element={<VisitKosherPlaces />} />
              <Route path="/farmersmarket" element={<FarmersMarket />} />
              <Route path="/fleamarketplace" element={<FleaMarketPlace />} />
              <Route path="/visitfoodtrucks" element={<VisitFoodTrucks />} />
              <Route path="/visitgroceries" element={<VisitGroceries />} />
              <Route path="/pitaplus" element={<PitaPlus />} />
              <Route path="/selamfoodmart" element={<SelamFoodMart />} />
              <Route path="/AfricanVillage" element={<AfricanVillage />} />
              <Route path="/vendor-template1" element={<VendorTemplate1 />} />
              <Route path="/vendor-template2" element={<VendorTemplate2 />} />
              <Route path="/vendor-template3" element={<VendorTemplate3 />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/SignInModal" element={<SignInModal />} />
              <Route path="/SignUpModal" element={<SignUpModal />} />
              <Route path="/mexican" element={<Mexican />} />
              <Route path="/american" element={<American />} />
              <Route path="/ethiopian" element={<Ethiopian/>} />
              <Route path="/eritrean" element={<Eritrean />} />
              <Route path="/african" element={<African />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/review-system" element={<ReviewSystem />} />
              <Route path="/forum" element={<CommunityForum />} />
              <Route path="/dashboard" element={<UserDashboard />} /> {/* Dashboard route */}
            </Routes>
          </div>

          {/* Right Navigation Section */}
          <div className="rightNavSection">
            <ImportantLinks />
            <TransitioningSectionForAd />
          </div>

          {/* Footer Section */}
          <Footer />
        </Template>
      </div>
    </Router>
  );
}

export default GoHubApp;
