import React, { useState, useEffect } from "react";
import "./TransitioningSectionForAd.css"; // Import the CSS file

function TransitioningSectionForAd() {
  const [currentIndex, setCurrentIndex] = useState(0); // Index of the currently displayed div

  const children = [
    <div className="sliding-ad-section"><img src={require("../images/AD1.jpg")} alt="Ad 1" className="sliding-ad-image" /></div>,
    <div className="sliding-ad-section"><img src={require("../images/AD2.jpg")} alt="Ad 2" className="sliding-ad-image" /></div>,
    <div className="sliding-ad-section"><img src={require("../images/AD3.jpg")} alt="Ad 3" className="sliding-ad-image" /></div>,
    <div className="sliding-ad-section"><img src={require("../images/AD4.jpg")} alt="Ad 4" className="sliding-ad-image" /></div>,
    <div className="sliding-ad-section"><img src={require("../images/AD5.jpg")} alt="Ad 5" className="sliding-ad-image" /></div>,
    <div className="sliding-ad-section"><img src={require("../images/AD6.jpg")} alt="Ad 6" className="sliding-ad-image" /></div>,
    <div className="sliding-ad-section"><img src={require("../images/AD7.jpg")} alt="Ad 7" className="sliding-ad-image" /></div>,
    <div className="sliding-ad-section"><img src={require("../images/AD8.jpg")} alt="Ad 8" className="sliding-ad-image" /></div>,
    <div className="sliding-ad-section"><img src={require("../images/AD7.jpg")} alt="Ad 7" className="sliding-ad-image" /></div>,
    <div className="sliding-ad-section"><img src={require("../images/AD8.jpg")} alt="Ad 8" className="sliding-ad-image" /></div>,
    <div className="sliding-ad-section"><img src={require("../images/AD9.jpg")} alt="Ad 9" className="sliding-ad-image" ></img></div>,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % children.length); // Cycle through the child divs
    }, 3000); // 3 seconds interval

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [children.length]);

  return (
    <div className="sliding-ad-carousel-container">
      {children.map((child, index) => (
        <div
          key={index}
          className="sliding-ad-slide"
          style={{
            transform: `translateX(${(index - currentIndex) * 100}%)`,
          }}
        >
          {child}
        </div>
      ))}
    </div>
  );
}

export default TransitioningSectionForAd;
