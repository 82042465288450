import React from "react";
import "./SelamFoodMart.css";

// Import hero image and gallery images dynamically
const heroImage = require("../images/heroselam.jpg");
const galleryImages = Array.from({ length: 13 }, (_, i) =>
  require(`../images/selam${i + 1}.jpeg`)
);

const SelamFoodMart = () => {
  return (
    <div className="selam-food-mart">
      {/* Hero Section */}
      <header className="hero" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="hero-content solid-bg">
          <h1 className="hero-title">Selam Food Mart</h1>
          <p className="hero-subtitle">Your go-to Ethiopian - Eritrean and International Grocery Store</p>
        </div>
      </header>

      {/* Store Info Section */}
      <section className="store-info">
        <div className="store-info-content">
          <h2>100% Fresh & Organic</h2>
          <p>Experience the best quality food items at Selam Food Mart.</p>
        </div>
      </section>

      {/* Address Section */}
      <section className="address">
        <h3>Find Us Here</h3>
        <p className="highlighted-text">5757 Evers Rd, San Antonio, TX 78238</p>
        <p className="phone">📞 (210) 332-5220</p>
      </section>

      {/* Highlights Section */}
      <section className="highlights">
        <div className="highlight-box">
            
          <h3>Our Highlights</h3>
          <ul>
            <li>✔️ Accepts SNAP/EBT</li>
            <li>⏰ Open until 12 AM</li>
            <li>🍏 Fresh and organic products</li>
          </ul>
        </div>
      </section>

      {/* Product Display */}
      <section className="product-display">
        <div className="product-grid">
          <div className="product-item fresh">
            <h3>Fresh Fruits & Vegetables</h3>
            <p>Locally sourced and organic options available.</p>
          </div>
          <div className="product-item spices">
            <h3>Ethiopian Spices & Grains</h3>
            <p>Authentic flavors straight from Ethiopia.</p>
          </div>
          <div className="product-item groceries">
            <h3>Other Food</h3>
            <p>Mediterranean (Foul - Fava Beans), Indian (Ghee), Mexican Tajin seasoning, herbs and spices.</p>
          </div>
        </div>
      </section>

      {/* Gallery Section */}
      <section className="gallery">
        <h2>Store Gallery</h2>
        <div className="gallery-grid">
          {galleryImages.map((src, index) => (
            <div key={index} className="gallery-item">
              <img src={src} alt={`Selam Store ${index + 1}`} className="gallery-img" />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default SelamFoodMart;
